
import React from 'react';
import './Dashboard.css';
import investor from '../assets/images/mainicons/inv.jpg';
import distributor from '../assets/images/mainicons/dist.jpg';
import amc from '../assets/images/mainicons/amc.jpg';
import about from '../assets/images/mainicons/about_val.jpg';
import {RedirectLinks} from '../utils/Constants';

const Box = ({linkTo, src, alt}) => {
  return (
    <div
      style={{
        backgroundColor: '#fff',
        width: 220,
        height: 180,
        margin: 10,
        borderRadius: 10,
      }}
      className="hoverable">
      <a
        href={linkTo}
        target='_blank'
        rel='noopener noreferrer'
        style={{
          textDecoration: 'none',
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}>
        {alt === 'About Validus Fintech' ? (
          <img src={src} alt={alt} width={200} height={140} />
        ) : (
          <img src={src} alt={alt} width={200} height={170} />
        )}
      </a>
      <div style={{
            textAlign: 'center',
            width: 200,
            margin: 10,
            color: 'white',
          }}>{alt}</div>
    </div>
  );
};

const Dashboard = () => {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      className="background">
      <div
        style={{
          textAlign: 'center',
          fontSize: 20,
          marginBottom: 400,
          color: 'white',
        }}>
        Solutions for Alternates @ Validus Fintech
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          position: 'fixed',
          width: '100%',
        }}>
        <Box linkTo={RedirectLinks.investor} src={investor} alt="Investor Services" />
        <Box
          linkTo={RedirectLinks.distributor}
          src={distributor}
          alt="Distributor Services"
        />
        <Box linkTo={RedirectLinks.amc} src={amc} alt="AMC Services"/>
        <Box linkTo={RedirectLinks.aboutVal} src={about} alt="About Validus Fintech" />
      </div>
    </div>
  );
};

export default Dashboard;
