import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import './App.css';
import {Dashboard} from './pages';

const App = () => {
  return (
    <div style={{overflowX: 'hidden'}}>
      <BrowserRouter>
        {/*  This div is the entire Webpage */}
        <div>
          {/* <div style={{width: '100%'}}
          >
            <Navbar />
          </div> */}
          <div>
            <Routes>
              {/* Dashboard or Home*/}
              <Route path="/" element={<Dashboard />} />
            </Routes>
          </div>
          {/* <div>
            <Footer />
          </div> */}
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
